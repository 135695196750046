import { createApp } from 'vue';
import VueSocketIO from 'vue-3-socket.io';
import { createRouter, createWebHistory } from 'vue-router';
import { io } from 'socket.io-client';
import { APP_SOCKET_IO_URL } from '@/consts/env';
import dayjs from 'dayjs';
import { getExamAppTokenFromQuery, getExamIdFromQuery } from './utils';
import App from './App.vue';
import store from './store';
import ExamView from './components/ExamView.vue';
import './index.css';

const app = createApp(App);

const routes = [{ path: '/', component: ExamView }];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const SocketIOConnection = io(APP_SOCKET_IO_URL, {
  transports: ['websocket'],
  query: { user: 'EXAM', examId: getExamIdFromQuery(), token: getExamAppTokenFromQuery() },
});

const SocketIO = new VueSocketIO({
  debug: true,
  connection: SocketIOConnection,
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_',
  },
});

document.addEventListener('keyup', event => {
  switch (event.code) {
    case 'ArrowLeft':
      store.dispatch('prev');
      break;
    case 'ArrowRight':
      store.dispatch('next');
      break;
    default:
      break;
  }
});

app.config.globalProperties.$dayjs = dayjs;

app.use(SocketIO as any);
app.use(router);
app.provide('$store', store);
app.use(store);
app.mount('#app');

export default app;
