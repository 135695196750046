import { createStore } from 'vuex';
import axios from 'axios';
import { getExamAppTokenFromQuery, getExamIdFromQuery } from '@/utils';
import { AudioElement } from '@/interfaces';
import { APP_API_URL } from '@/consts/env';
import exam from './exam';
import session from './session';
import daily from './daily';

export interface State {
  brand: string;
  loading: boolean;
  exam2: never;
  examStarted: boolean;
  originUrl: string;
  exam: any;
  daily: any;
}

const ac = new AbortController();
let timer: number;

const audio: AudioElement = {
  player: new Audio(),
  sources: [],
  current: 0,
};

/* eslint-disable */
async function playAudio(item: number) {
  ac.abort();
  if (!audio.sources[item]) return;

  audio.current = item;
  audio.player.src = audio.sources[item];
  audio.player.volume = 1.0;
  audio.player.load();

  if (store.getters.getDelay) {
    await sleep(store.getters.getDelay);
  }
  await audio.player.play();
  audio.player.onended = () => playAudio(item + 1);
}

function sleep(s: number | null | undefined) {
  return new Promise(resolve => {
    timer = window.setTimeout(resolve, s! * 1000);
  });
}
/* eslint-enable */

const store = createStore({
  modules: {
    exam,
    session,
    daily,
  },

  state: () => ({
    brand: 'ICAO4U',
    loading: true,
    exam2: null,
    examStarted: false,
    originUrl: '',
  }),
  actions: {
    setAudioSources(_, sources: string[]) {
      clearTimeout(timer);
      audio.current = 0;
      audio.sources = sources;
      audio.player.pause();
      playAudio(audio.current);
    },

    replay() {
      if (!audio.sources.length) return;

      audio.player.pause();
      playAudio(0);
    },

    next({ commit, dispatch, getters }) {
      commit('exam/next');
      dispatch('setAudioSources', getters['exam/currentView'].audio);
    },

    prev({ commit, dispatch, getters }) {
      commit('exam/prev');
      dispatch('setAudioSources', getters['exam/currentView'].audio);
    },
    init({ commit }, { state }) {
      commit('exam/init', state);
    },
    initMaster({ commit }, { state }) {
      commit('initMaster', state);
    },
    setOriginUrl({ commit }, { originUrl }) {
      commit('originUrl', originUrl);
    },
    loaded({ commit, dispatch, getters }) {
      commit('loaded');
      dispatch('setAudioSources', getters['exam/currentView'].audio);
    },
    setExamStarted({ commit }, examStarted) {
      commit('examStarted', examStarted);
    },
    getUser(_, email) {
      return axios
        .get(`${process.env.MIX_API_URL}get-user?email=${email}`)
        .then(response => response.data)
        .catch(error => {
          console.log(error);
        });
    },
    updateDailyParticipants({ commit }, participants) {
      commit('session/updateDailyParticipants', participants);
    },

    updateSession({ commit }, sessionData) {
      commit('session/updateSession', sessionData);
    },
    updateExaminer({ commit }, examinerData) {
      commit('session/updateExaminer', examinerData);
    },
    updateExamVideo({ commit }, video: MediaStreamTrack) {
      commit('daily/updateExamVideo', video);
    },

    updateStudentVideo({ commit }, video: MediaStreamTrack) {
      commit('daily/updateStudentVideo', video);
    },

    getExamQuestions({ commit, dispatch }) {
      return axios
        .get(`${APP_API_URL}/api/exam-app/exam/${getExamIdFromQuery()}?token=${getExamAppTokenFromQuery()}`)
        .then(response => {
          commit('exam/init', response.data);
          commit('examStarted');
          dispatch('loaded');

          const createPreloadImageElement = (image: string) => {
            const linkElement = document.createElement('link');
            linkElement.setAttribute('rel', 'preload');
            linkElement.setAttribute('as', 'image');
            linkElement.setAttribute('href', image);
            document.head.appendChild(linkElement);
          };

          response.data.steps.forEach((module: any) => {
            if (module.image) {
              createPreloadImageElement(module.image);
            }

            if (module.questions) {
              module.questions.forEach((question: any) => {
                if (question.image) {
                  createPreloadImageElement(question.image);
                }
              });
            }
          });
        })
        .catch(error => {
          commit('exam/failed', error.message);
          dispatch('loaded');
        });
    },
  },

  mutations: {
    loaded(state): void {
      state.loading = false;
    },
    initMaster(state, payload): void {
      Object.assign(state, payload);
    },
    storeExam(state, data): void {
      state.exam2 = data;
    },
    examStarted(state, value = true): void {
      state.examStarted = value;
    },
    originUrl(state, value = ''): void {
      state.originUrl = value;
    },
  },

  getters: {
    getDelay: (state, getters) => getters['exam/currentModule']?.delay,
    examId: (state, getters) => getters['exam/examId'],
    examStarted: state => state.examStarted,
    results: (state, getters) => getters['exam/results'],
    originUrl: state => state.originUrl,
  },
});

export default store;
