import {
  Audio,
  NavGroup,
  NavModule,
  Result,
  ResultUpdate,
  View,
} from '@/interfaces';
import { ModuleType, RecState } from '@/enums';

// Common fields for Module & Question
//  Not exported intentionally
interface Item {
  audio: undefined | Audio[];
  content: string;
  image: undefined | string;
}

export interface Question extends Item {
  set: number;
  number: number;
}

export interface Module extends Item {
  key?: null | string;
  delay?: null | number;
  title: string;
  type: ModuleType;
  questions: Question[];
  isQuestion: boolean;
  video: boolean;
  recState: RecState;
}

export interface State {
  view?: Module;
  error: {
    isError: boolean;
    message?: string;
  };
  current: {
    module: number;
    question: null | number;
  };
  modules: Module[];
  mark?: string;
  id: string;
  results: Result;
  overallResult: number;
}

const NAV_MODULES = [ModuleType.ATIS, ModuleType.DESCRIBING, ModuleType.LISTENING, ModuleType.SPEAKING];

function isQuestionView(view: View | Module): boolean {
  return NAV_MODULES.indexOf(view.type) !== -1;
}

function nextStepRecState(state: State): null | RecState {
  return state.modules[state.current.module + 1 || 1]?.recState ?? null;
}

function previousStepRecState(state: State): null | RecState {
  return state.modules[state.current.module - 1]?.recState ?? null;
}

function currentModule(state: State): null | Module {
  return state.modules[state.current.module || 0] ?? null;
}

function examId(state: State): string {
  return state.id || '';
}

function results(state: State): Result {
  return state.results;
}

function overallResult(state: State): number {
  return state.overallResult;
}

function modules(state: State): Module[] {
  return state.modules;
}

function examError(state: State): { isError: boolean; message?: string } {
  return state.error;
}

function currentQuestion(state: State): null | Question {
  return state.current.question !== null ? currentModule(state)?.questions[state.current.question] ?? null : null;
}

function currentView(state: State): View {
  const module = currentModule(state);

  // if (!module) return null; // throw new Error('Module not found')

  const question = currentQuestion(state);
  // const isModuleIntro = state.current.question === null;
  const showVideo = state.current.question === null && module?.key !== '0IN';
  const audio = question?.audio ?? module?.audio ?? null;
  const image = question?.image ?? module?.image ?? '';

  return {
    // Generic rule is: first search for item in question, then module, then provide fallback
    audio: audio ? audio.map?.(a => (a.src ? a.src : '')) : [],
    content: question?.content ?? module?.content ?? '',
    image,
    recState: module?.recState,
    isQuestion: module ? isQuestionView(module) : false,
    title: ((module?.key ? `${module?.key}: ${module?.title}` : module?.title) ?? '').toUpperCase(),
    type: module?.type || ModuleType.INTRO,
    // TODO: change complicated logic - maybe create show_video column in DB
    video: showVideo && !image,
  };
}

function navItems(state: State): Map<string, NavGroup> {
  const groups = new Map<string, NavGroup>();

  for (let i = 0; i < state.modules.length; i += 1) {
    const module: Module = state.modules[i];

    /* eslint-disable */
    if (!isQuestionView(module)) continue;
    /* eslint-enable */

    const moduleIndex = state.current.module ?? 0;
    const navModule: NavModule = {
      active: moduleIndex === i,
      wasActive: moduleIndex > i,
      questions: [],
    };

    for (let j = 0; j < module.questions.length; j += 1) {
      navModule.questions[j] = {
        active: navModule.active && state.current.question === j,
        wasActive: navModule.wasActive || (navModule.active && (state.current.question ?? 0) > j),
      };
    }

    const type = ModuleType.ATIS === module.type ? ModuleType.LISTENING : module.type;

    if (groups.has(type)) {
      groups.get(type)?.modules.push(navModule);
    } else {
      groups.set(type, {
        name: type,
        active: true,
        wasActive: true,
        modules: [navModule],
      });
    }
  }

  return groups;
}

export default {
  namespaced: true,

  state: (): State => ({
    error: {
      isError: false,
      message: undefined,
    },
    current: {
      module: 0,
      question: null,
    },
    modules: [],
    mark: undefined,
    id: '',
    results: {
      pronunciation: 0,
      structure: 0,
      vocabulary: 0,
      fluency: 0,
      comprehension: 0,
      interactions: 0,
    },
    overallResult: 0,
  }),

  getters: {
    currentModule,
    currentQuestion,
    currentView,
    navItems,
    examId,
    results,
    overallResult,
    modules,
    examError,
    nextStepRecState,
    previousStepRecState,
  },

  mutations: {
    init(state: State, payload: any): void {
      state.error.isError = false;
      state.error.message = undefined;

      console.log(payload);

      const examModules = [
        {
          audio: [],
          content: '',
          image: '',
          isQuestion: false,
          title: '0WELC: UNDEFINED',
          type: 'intro',
          video: true,
          recState: RecState.START_RECORDING,
        },
        ...payload.steps.map((module: Module) => ({ ...module, recState: RecState.RECORDING })),
        {
          audio: [],
          content: '',
          image: '',
          isQuestion: false,
          title: '0WELC: UNDEFINED',
          type: 'intro',
          video: true,
          recState: RecState.STOP_RECORDING,
        },
      ];

      Object.assign(state.modules, examModules);
      state.mark = payload.mark;
    },

    failed(state: State, error: string) {
      state.error.isError = true;
      state.error.message = error;
    },

    next(state: State): void {
      const module = currentModule(state);
      if (!module) throw Error('Out of bounds');

      if (state.current.question !== null) {
        // increment to next question if not in intro
        state.current.question += 1;
      } else if (module.questions?.[0]) {
        // move to first question if in intro if next module exists
        state.current.question = 0;
      }

      const nextQuestionExists = module.questions?.[state.current.question ?? -1];
      const nextModule = state.modules[state.current.module + 1];

      // if next question in current module does not exist move to next module
      if (!nextQuestionExists && nextModule) {
        state.current.module += 1;
        state.current.question = null;
      }
    },

    prev(state: State): void {
      const module = currentModule(state);

      if (!module) throw Error('Out of bounds');

      if (state.current.question) {
        state.current.question -= 1;
      } else if (state.current.question !== null) {
        state.current.question = null;
      } else if (state.modules[state.current.module - 1]) {
        const questions = state.modules[state.current.module - 1].questions ?? [];
        state.current.module -= 1;
        state.current.question = questions.length - 1 >= 0 ? questions.length - 1 : null;
      }
    },

    updateResult(state: State, data: ResultUpdate) {
      state.results[data.key] = data.value;
    },
    updateOverallResult(state: State): void {
      const values = Object.values(state.results);
      state.overallResult = Math.min(...values);
    },
  },
};
